<template lang="">
    <Detail />
</template>
<script>
export default {
    components:{
        Detail : () => import("@/components/adverts/Detail")
    }
}
</script>
<style lang="">
    
</style>